/*
 * @Author: lina
 * @Date: 2024-08-29
 * @LastEditors: lina
 * @LastEditTime: 2024-08-29
 * @Descripttion: 手写板接口
 */
import HTTP from "@utils/http";
const PLASCH = "/school/plaboardschool/";
const PLASCH2 = "/school/stuuser/";
/**
 * 首页数据
 * @param {object} params
 * @returns
 */
export async function $boardList(params) {
  try {
    return await HTTP.post(PLASCH + "all_list", params);
  } catch (error) {
    return error;
  }
}
/**
 * 查找班级
 * @param {object} params
 * @returns
 */
export async function $nosendTreelist(params) {
  try {
    return await HTTP.post(PLASCH2+"nosend_treelist", params);
  } catch (error) {
    return error;
  }
}
/**
 * 查找学生列表
 * @param {object} params
 * @returns
 */
export async function $stuUserAllList(params) {
  try {
    return await HTTP.post(PLASCH2+"list", params);
  } catch (error) {
    return error;
  }
}
/**
 * 绑定
 * @param {object} params
 * @returns
 */
export async function $bindingStuUser(params) {
  try {
    return await HTTP.post(PLASCH+"binding_stuuser", params);
  } catch (error) {
    return error;
  }
}
/**
 * 绑定记录
 * @param {object} params
 * @returns
 */
export async function $bindingList(params) {
  try {
    return await HTTP.post(PLASCH+"board_list", params);
  } catch (error) {
    return error;
  }
}
/**
 * 回收批次历史记录
 * @param {object} params
 * @returns
 */
export async function $getHhistory(params) {
  try {
    return await HTTP.post(PLASCH+"get_history", params);
  } catch (error) {
    return error;
  }
}

/**
 * 一键回收
 * @param {object} params
 * @returns
 */
export async function $recoveryBoard(params) {
  try {
    return await HTTP.post(PLASCH+"recovery_board", params);
  } catch (error) {
    return error;
  }
}

/**
 * 导出绑定记录
 * @param {object} params
 * @returns
 */
export async function $exportPlaBoardSend(params) {
  try {
    return await HTTP.post(PLASCH+"export", params);
  } catch (error) {
    return error;
  }
}